<template>
  <div align="center">
    <section class="e-content" align="left">
      <h1>
        <img
          style="float: right; margin: 0 0 10pt 10pt; height: 120pt"
          src="../assets/obesity_compressed.jpg"
        />
        The Obesity Epidemic&nbsp;&nbsp;
      </h1>
      <h6>by Escher Wright-Dykhouse</h6>
      <br />
      <h4>
        <img style="height: 20pt" src="../assets/question2.gif" />
        Essential Question
      </h4>
      <p>What is the most effective way of combating mass obesity?</p>
      <br />
      <h4>
        <img style="height: 20pt; margin-right: 3pt" src="../assets/gb.gif" />
        Introduction
      </h4>
      <p>
        The purpose of this inquiry project is to determine why obesity rates
        continue to rise in the United States and other developed countries, and
        what we could do to lower it. I chose this topic to spread awareness
        about this social issue and to learn more about what causes it. My
        impression going into this project is that little has been done on a
        national level to combat obesity, which is why rates continue to rise.
      </p>
      <br />
      <h4>
        <img style="height: 20pt; margin-right: 3pt" src="../assets/news.gif" />
        Sources
      </h4>
      <div class="subnav">
        <ul class="nav nav-pills">
          <li><router-link to="/sources/0">Source #1</router-link></li>
          <li><router-link to="/sources/1">Source #2</router-link></li>
          <li><router-link to="/sources/2">Source #3</router-link></li>
        </ul>
      </div>
      <br />
      <h4>
        <img
          style="height: 20pt; margin-right: 3pt"
          src="../assets/arrow27.gif"
        />
        Conclusion
      </h4>
      <router-link to="/conclusion">Go To Conclusion</router-link>
    </section>
  </div>
</template>

<script>
export default {
  name: "home-page",
};
</script>

<style scoped></style>
